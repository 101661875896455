import '@danfoss/mosaic/css/mosaic.css';
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { LoginState } from './UserMenuSelector';
import { useAuth0 } from "@auth0/auth0-react";
import { getUserLanguage } from '../../utils/getUserLanguage';
import AppSwitcher from '@danfoss/app-switcher';
import languages from '../../localization/languages.json';


// When running in production and development with micro-site package installed from aritfactory we must import index.min.css
// When running in development with link to local micro-site package inlne styles are used so no index.min.css is available
try {
    require('@danfoss/app-switcher/dist/index.min.css');
} catch (e) {
}

interface HeaderProps {
    user?: DipUser;
}

interface DipUser {
    name: string,
    email: string
}

const Header: React.FC<HeaderProps> = () => {
    const { user } = useAuth0();
    const langDropdownRef = useRef<HTMLLIElement>(null);
    const [languageDropdownActive, setLanguageDropdownActive] = useState<boolean>(false);
    const [language, switchLanguage] = useState<any>({
        culture: 'en',
        text: 'English'
    });

    useEffect(() => {
        if (user) {
            let lang = languages.filter(x => x.id == getUserLanguage(user))[0];
            switchLanguage({
                culture: lang.id,
                text: lang.name
            });
        }
    }, [user])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (langDropdownRef.current && !langDropdownRef.current.contains(event.target as Node)) {
                setLanguageDropdownActive(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    const toggleLang = (elem: any, culture: string) => {
        switchLanguage({
            culture: culture,
            text: elem.innerHTML
        });
    }

    return (
        <header className="df-header">
            <div className="df-container df-header-container">
                <div className="df-logo-container">
                    <Link to="/"
                        rel="noopener noreferrer"
                        className="df-logo"
                        aria-label="Home page">
                        <picture>
                            <img src="https://cdn.mosaic.danfoss.com/npm/mosaic-assets@latest/dist/logos/danfoss.svg"
                                alt="Danfoss logo" />
                        </picture>
                    </Link>
                </div>
                <nav className="header-nav-container"
                    aria-label="header links">
                    <ul>
                        <li>
                            <AppSwitcher
                                isUserLoggedIn={!!user}
                                lang={language.culture}
                                configValues={{
                                    domain: process.env.REACT_APP_AUTH0_ISSUER_BASE_URL!,
                                    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID!,
                                }}
                            />
                        </li>
                        <li className="df-nav-item" ref={langDropdownRef}>
                            <ul>
                                <li slot="link"
                                    className={(languageDropdownActive ? 'open ' : '') + "df-nav-item"}
                                    onClick={(() => setLanguageDropdownActive(!languageDropdownActive))}>
                                    <a className="df-header-link df-link-tertiary" href="javascript:;" aria-label="header link">
                                        <span className="df-icon">
                                            <svg width="24" height="24" viewBox="0 0 24 24">
                                                <use href="./images/icons/language.svg#language"></use>
                                            </svg>
                                        </span>
                                        <span id="lang" translate="no">{language.text}</span>
                                    </a>
                                    <ul className="df-context-menu-overlay df-shadow p-1 align-right" role="menu" id="menu">
                                        {languages.map((lang, index) => {
                                            return (
                                                <li role="menuitem" className="df-menu-item"
                                                    key={index}
                                                    onClick={(event) => toggleLang(event.target, lang.id)}>
                                                    {lang.name}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <LoginState user={user} />
                    </ul>
                </nav>
            </div>
        </header>
    )
}

export default Header