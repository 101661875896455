import React from 'react';
import { Helmet } from 'react-helmet';
import Header from './components/header/Header';
import "@danfoss/mosaic/css/mosaic.css";

function generateNonce(length: number) {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  if (typeof sessionStorage !== 'undefined') sessionStorage.setItem("nonce", text)
  return text;
}

const generateCsp = (): [csp: string, nonce: string] => {
  const nonce = generateNonce(16);
  let csp = process.env.REACT_APP_CSP;
  csp = csp?.replaceAll("nonce-", "nonce-" + nonce) || "";
  return [csp, nonce];
};

function App() {
  const [csp, nonce] = generateCsp();

  return (
    <div className='df-mosaic'>
      <Helmet>
        <meta httpEquiv='Content-Security-Policy' content={csp} />
      </Helmet>
      <Header />
    </div>
  );
}

export default App;

